<script setup lang="ts">
import OrgSwitcher from '~/components/OrgSwitcher.vue';
import { useContainersStore } from '~/store/containers';
import { useAppBarSearch } from '~/composables/useAppBarSearch';
import { usePageTitle } from '~/composables/usePageTitle';
import { FilterIcon, GroupByIcon, SortIcon, ViewIcon } from '~/utils/svg.util';
import ContainerPageActionBar from '~/components/layout/AppBars/ContainerPageActionBar.vue';

const containersStore = useContainersStore();
const { t } = useI18n();

const selectedContainers: any = computed(() => containersStore.selectedContainers);

const { searchQuery, onSearchChange, onSearchClear } = useAppBarSearch();
const { pageTitle } = usePageTitle();

const placeholders = ['searchByContainer', 'searchByPodPolTs', 'searchByVesselCarrier', 'searchByCustomer'];
const currentPlaceholderIndex: any = ref(0);

onMounted(() => {
  startPlaceholderRotation();
});

const startPlaceholderRotation = () => {
  setInterval(() => {
    currentPlaceholderIndex.value = (currentPlaceholderIndex.value + 1) % placeholders.length;
  }, 2000);
};

const getSearchPlaceholder = () => {
  return t(`common.${placeholders[currentPlaceholderIndex.value]}`).toString();
};
</script>

<template>
  <v-container
    fluid
    class="py-5 px-8"
  >
    <v-row
      v-if="!selectedContainers.length"
      no-gutters
    >
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <div>
          <h2 class="heading-desktop-4 text-black-1">
            {{ pageTitle }}
          </h2>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center"
      >
        <div>
          <v-responsive
            class="mx-auto"
            width="344"
          >
            <v-text-field
              v-model="searchQuery"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              hide-details
              clearable
              single-line
              class="app-bar-search"
              density="comfortable"
              clear-icon="mdi-close"
              :placeholder="getSearchPlaceholder()"
              @keyup="onSearchChange"
              @click:clear="onSearchClear"
            />
          </v-responsive>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex justify-end"
      >
        <div class="d-flex align-center">
          <div class="d-flex header-filter align-center">
            <div class="filter_item">
              <v-tooltip
                location="bottom"
              >
                <template #activator="{ props }">
                  <viewIcon
                    v-bind="props"
                    class="cursor-pointer"
                    @click="containersStore.setShowContainerViewModal(true)"
                  />
                </template>
                <span>{{ $t("common.tableView") }}</span>
              </v-tooltip>
            </div>
            <div class="filter_item">
              <v-tooltip
                location="bottom"
              >
                <template #activator="{ props }">
                  <groupByIcon
                    v-bind="props"
                    class="cursor-pointer"
                    @click="containersStore.setShowGroupByModal(true)"
                  />
                </template>
                <span>{{ $t("containerPage.groupBy") }}</span>
              </v-tooltip>
            </div>
            <div class="filter_item">
              <v-tooltip
                location="bottom"
              >
                <template #activator="{ props }">
                  <SortIcon
                    v-bind="props"
                    class="cursor-pointer"
                    @click="containersStore.setShowSortModal(true)"
                  />
                </template>
                <span>{{ $t("containerPage.sortBy") }}</span>
              </v-tooltip>
            </div>
            <div class="filter_item">
              <v-tooltip
                location="bottom"
              >
                <template #activator="{ props }">
                  <FilterIcon
                    v-bind="props"
                    class="cursor-pointer"
                    @click="containersStore.setFiltersSideModalStatus(true)"
                  />
                </template>
                <span>{{ $t("common.filters") }}</span>
              </v-tooltip>
            </div>
          </div>
          <OrgSwitcher />
        </div>
      </v-col>
    </v-row>
    <ContainerPageActionBar v-if="selectedContainers.length" />
  </v-container>
</template>

<style lang="scss">
.header-filter {
  column-gap: 36px;
  margin-right: 36px;
  &_item {
     img {
       width: 18px;
     }
  }
  svg.nuxt-icon{
   font-size: 20px !important;
  }
}
</style>
